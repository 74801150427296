<script setup lang="ts">
import { useUser } from '@/components/Header/hooks/use-user';
import Workspace from '@/components/Header/Workspace.vue';
import { VSkeletonLoader } from 'vuetify/labs/VSkeletonLoader';
import { computed } from 'vue';
const { isLoading, data: user } = useUser();

const initials = computed<string>(() => {
  if (!user.value) {
    return '';
  }
  return user.value.name
    .split(' ')
    .splice(0, 2)
    .map(([letter]: string) => letter ?? '')
    .join('');
});
</script>

<template>
  <v-app-bar>
    <v-skeleton-loader v-if="isLoading" type="list-item-avatar" />
    <v-container v-if="user">
      <v-row align-items="center" justify="space-between">
        <v-col class="d-flex align-center">
          <v-avatar class="mr-2" color="info" variant="outlined">
            <span class="uppercase" v-text="initials" />
          </v-avatar>
          <h3 v-text="user.name || user.email" />
        </v-col>
        <v-col class="d-flex align-center justify-end">
          <Workspace />
        </v-col>
      </v-row>
    </v-container>
  </v-app-bar>
</template>
