<script lang="ts" setup>
import { WEBAPP_URL } from '@/api/config';
import { isMeetingRoute } from '@/router/is-meeting-route';
import { INDEX, ON_MEETING } from '@/router/router';
import { useQuery } from '@tanstack/vue-query';
import zoomSdk from '@zoom/appssdk';
import { watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';

const route = useRoute();
const router = useRouter();

const {
  isLoading,
  isFetched,
  isError,
  data: status,
} = useQuery({
  queryFn: () =>
    zoomSdk.config({
      capabilities: [
        // 'onSendAppInvitation',
        // 'onShareApp',
        // 'onActiveSpeakerChange',
        // 'onMeeting',

        // connect api and event
        'connect',
        'onConnect',
        'onParticipantChange',
        // 'postMessage',
        'onMessage',

        // in-client api and event
        'authorize',
        'onAuthorized',
        // 'promptAuthorize',
        // 'getUserContext',
        // 'onMyUserContextChange',

        // 'sendAppInvitation',
        // 'setVirtualBackground',
        // 'removeVirtualBackground',
        // 'getSupportedJsApis',
        'openUrl',
        'getMeetingContext',
        'getRunningContext',
        // 'showNotification',
        // 'sendAppInvitationToAllParticipants',
        // 'sendAppInvitationToMeetingOwner',
        // 'showAppInvitationDialog',
        'getMeetingParticipants',
        'getMeetingUUID',
        // 'getChatContext',
        'getMeetingJoinUrl',
        // 'listCameras',
        // 'expandApp',
        // 'allowParticipantToRecord',
        // 'getRecordingContext',
        // 'cloudRecording',
        // 'setVideoMirrorEffect',
        // 'shareApp',
      ],
      version: '0.16.0',
    }),
  queryKey: ['zoom_config'],
});

watch(
  () => status.value?.runningContext,
  (context) => {
    if (context === 'inMeeting' && !isMeetingRoute(route)) {
      router.push({ name: ON_MEETING });
    }
    if (context !== 'inMeeting' && isMeetingRoute(route)) {
      router.push({ name: INDEX });
    }
  },
);

watch(isError, (rejected: boolean) => {
  if (rejected) {
    window.location.href = WEBAPP_URL;
  }
});
</script>
<template>
  <div
    v-if="isLoading"
    class="d-flex justify-center align-center flex-grow-1 h-100 w-100"
  >
    <v-progress-circular
      indeterminate
      :size="25"
      :width="3"
      color="primary"
      class="mr-4"
    />Configuring zoom...
  </div>
  <slot v-if="isFetched" />
  <div v-if="isError">Hello from browser</div>
</template>
