export enum Status {
  INIT,
  PENDING,
  FULFILLED,
  REJECTED,
}

export const isRejected = (status: Status): status is Status.REJECTED =>
  status === Status.REJECTED;
export const isInit = (status: Status): status is Status.INIT =>
  status === Status.INIT;
export const isPending = (status: Status): status is Status.PENDING =>
  status === Status.PENDING;
export const isFulfilled = (status: Status): status is Status.FULFILLED =>
  status === Status.FULFILLED;
