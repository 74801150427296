import { SUPABASE_KEY, SUPABASE_URL } from '@/api/config';
import {
  clearTokens,
  getAccessToken,
  getRefreshToken,
  setTokens,
} from '@/api/cookies';
import {
  isFulfilled,
  isInit,
  isPending,
  isRejected,
  Status,
} from '@/utils/status';
import { createClient } from '@supabase/supabase-js';
import { computed, onMounted, ref } from 'vue';

export const client = createClient(SUPABASE_URL, SUPABASE_KEY, {
  auth: { autoRefreshToken: true, persistSession: false },
  realtime: {
    params: {
      eventsPerSecond: 4,
    },
  },
});

const status = ref<Status>(Status.INIT);
const accessToken = ref<string | undefined>(getAccessToken());

const registerSession = async () => {
  if (!isInit(status.value)) {
    return;
  }
  client.auth.onAuthStateChange((event, session) => {
    if (['SIGNED_OUT', 'USER_DELETED'].includes(event)) {
      accessToken.value = undefined;
      status.value = Status.REJECTED;
      clearTokens();
    }
    if (session && ['SIGNED_IN', 'TOKEN_REFRESHED'].includes(event)) {
      accessToken.value = session.access_token;
      status.value = Status.FULFILLED;
      setTokens({
        accessToken: session.access_token,
        refreshToken: session.refresh_token,
        expiresAt: session.expires_at,
      });
    }
  });

  const cookieToken = getAccessToken();
  const refreshToken = getRefreshToken();

  if (!cookieToken || !refreshToken) {
    status.value = Status.REJECTED;
    return;
  }
  status.value = Status.PENDING;
  const { data, error } = await client.auth.setSession({
    access_token: cookieToken,
    refresh_token: refreshToken,
  });
  if (error) {
    status.value = Status.REJECTED;
  }
  if (data.session) {
    status.value = Status.FULFILLED;
    accessToken.value = data.session?.access_token;
  }
};

export const useSupabase = () => {
  onMounted(registerSession);

  const setAccessToken = (token: string): void => {
    accessToken.value = token;
  };

  return {
    status,
    accessToken,
    setAccessToken,
    client,
    isLoading: computed(() => isPending(status.value)),
    isFulfilled: computed(() => isFulfilled(status.value)),
    isRejected: computed(() => isRejected(status.value)),
    isInit: computed(() => isInit(status.value)),
  };
};
