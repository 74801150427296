import is from '@sindresorhus/is';
import { Cookies } from 'typescript-cookie';
import { CookieAttributes } from 'typescript-cookie/dist/types';

export const ACCESS_TOKEN_COOKIE_KEY = 'ac-to';
export const REFRESH_TOKEN_COOKIE_KEY = 're-to';

export const getAccessToken = () => {
  const token = Cookies.get(ACCESS_TOKEN_COOKIE_KEY);
  return is.string(token) ? token : undefined;
};
export const defaultCookieConfig: CookieAttributes = {
  path: '/',
  sameSite: 'Lax',
  secure: true,
} as const;

export const getRefreshToken = () => {
  const token = Cookies.get(REFRESH_TOKEN_COOKIE_KEY);
  return is.string(token) ? token : undefined;
};
export const removeAccessToken = () =>
  Cookies.remove(ACCESS_TOKEN_COOKIE_KEY, defaultCookieConfig);
export const removeRefreshToken = () =>
  Cookies.remove(REFRESH_TOKEN_COOKIE_KEY, defaultCookieConfig);

export const setTokens = (session: {
  accessToken: string;
  refreshToken: string;
  expiresAt?: number;
}) => {
  Cookies.set(ACCESS_TOKEN_COOKIE_KEY, session.accessToken, {
    ...defaultCookieConfig,
    expires: session.expiresAt,
  });
  Cookies.set(REFRESH_TOKEN_COOKIE_KEY, session.refreshToken, {
    ...defaultCookieConfig,
    expires: session.expiresAt,
  });
};

export const clearTokens = () => {
  removeAccessToken();
  removeRefreshToken();
};
