import axios from 'axios';

export const getCredentials = () =>
  axios
    .get<{
      state: string;
      codeChallenge: string;
    }>('/pre-auth')
    .then(({ data }) => data);

export const getAccessToken = ({
  code,
  state,
}: {
  code: string;
  state: string;
}) =>
  axios
    .post<{ token: string }>('/on-auth', {
      code,
      state,
      href: window.location.href,
    })
    .then(({ data }) => data);
