<script setup lang="ts">
import { getAccessToken, getCredentials } from '@/api/server';
import { useSupabase } from '@/api/supabase.client';
import { client } from '@/api/trpc.client';
import { isIndexPath } from '@/router/is-index-path';
import is from '@sindresorhus/is';
import { useMutation } from '@tanstack/vue-query';
import zoomSdk from '@zoom/appssdk';
import { computed, watch, ref } from 'vue';
import { useRoute } from 'vue-router';

const isAuthError = ref<boolean>(false);
const {
  isLoading,
  isFulfilled,
  isRejected,
  client: supabase,
  setAccessToken,
} = useSupabase();

const { mutate: exchangeToken, isLoading: isChangingToken } = useMutation({
  mutationFn: async () => {
    const { refresh_token: refreshToken } =
      await client.auth.getCustomToken.query();
    return supabase.auth.refreshSession({ refresh_token: refreshToken });
  },
  mutationKey: ['noty-auth'],
});

zoomSdk.addEventListener('onAuthorized', async (event) => {
  try {
    const { token } = await getAccessToken({
      code: event.code,
      state: event.state,
    });
    setAccessToken(token);
    exchangeToken();
  } catch (e) {
    isAuthError.value = true;
    console.error(e);
    setTimeout(() => {
      window.location.reload();
    }, 5000);
  }
});

const route = useRoute();

const { mutate: authorize, isLoading: isAuthorizing } = useMutation({
  mutationKey: ['zoom-auth'],
  mutationFn: async () => {
    const credentials = await getCredentials();
    return zoomSdk.authorize(credentials);
  },
});

watch(
  () => isRejected.value,
  (rejected) => {
    /* only whitelisted clean urls can call zoom authorize method */
    if (!rejected) {
      return;
    }
    if (!isIndexPath(route.fullPath)) {
      window.location.href = '/';
      return;
    }
    authorize();
  },
);

const text = computed(
  () =>
    [
      isLoading.value && 'Looking for user...',
      isAuthorizing.value && 'Authorizing user...',
      isChangingToken.value && 'Log in user...',
    ].find(is.string) || 'Loading...',
);
</script>

<template>
  <div
    v-if="!isFulfilled && (isLoading || isAuthorizing || isChangingToken)"
    class="d-flex justify-center align-center flex-grow-1 h-100 w-100 text-white"
  >
    <v-progress-circular
      indeterminate
      :size="25"
      :width="3"
      color="primary"
      class="mr-4"
    />
    {{ text }}
  </div>
  <div
    v-if="isAuthError"
    class="d-flex justify-center align-center flex-grow-1 h-100 w-100 text-white"
  >
    <h4>Auth error. Please reload the page</h4>
  </div>

  <slot v-if="isFulfilled" />
</template>
