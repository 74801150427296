import '@/assets/styles/index.scss';
import { initSentry } from '@/utils/analytics/sentry';
import { vuetify } from '@/vuetify';
import { VueQueryPlugin } from '@tanstack/vue-query';
import { createApp } from 'vue';
import 'vuetify/styles';
import { router } from './router/router';

import ZoomApp from './ZoomApp.vue';

function main() {
  const app = createApp(ZoomApp);

  app
    .use(router)
    .use(vuetify)
    .use(VueQueryPlugin, {
      queryClientConfig: {
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
          },
        },
      },
    });
  initSentry(app, router);

  app.mount('#app');
}

main();
