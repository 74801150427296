import { createVuetify } from 'vuetify';

export const vuetify = createVuetify({
  theme: {
    defaultTheme: 'dark',
  },
  defaults: {
    VBtn: { variant: 'outlined', size: 'small', rounded: 'sm' },
    VTextField: { variant: 'outlined', density: 'compact' },
    VSelect: {
      variant: 'outlined',
      size: 'small',
      rounded: 'sm',
      density: 'compact',
    },
  },
});
