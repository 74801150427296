import { client } from '@/api/trpc.client';
import { useQuery } from '@tanstack/vue-query';
import { computed } from 'vue';

export const useUser = () =>
  useQuery({
    queryKey: ['user'],
    queryFn: () => client.auth.signIn.mutate(),
  });

export const usePrimaryWorkspace = () => {
  const { data } = useUser();

  const workspaceId = computed(() => data.value?.primaryAccount?.workspaceId);
  const enabled = computed(() => !!data.value?.primaryAccount?.workspaceId);

  return useQuery({
    queryKey: ['workspace', workspaceId],
    queryFn: () => client.workspaces.getById.query({ id: workspaceId.value }),
    enabled,
  });
};
