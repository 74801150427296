import { BACKEND_URL } from '@/api/config';
import is from '@sindresorhus/is';
import { Router } from 'vue-router';
import * as Sentry from '@sentry/vue';
import { App } from 'vue';

const getTrpcBackendHost = (): string => {
  return new URL(BACKEND_URL).host;
};

export const initSentry = (app: App, router: Router) => {
  if (!is.string(import.meta.env.VITE_SENTRY_DSN)) {
    console.warn('sentry not init');
    return;
  }

  Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracePropagationTargets: ['localhost', /^\//]
          .concat(getTrpcBackendHost())
          .filter(Boolean),
      }),
    ],
    tracesSampleRate: 0.01,
  });
};
