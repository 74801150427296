import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

export const INDEX = 'Index';
export const ON_MEETING = 'on-meeting';
export const INDEX_PATH = '/';
export const CONVERSATION = 'conversation';

const routes: Array<RouteRecordRaw> = [
  {
    path: INDEX_PATH,
    name: INDEX,
    meta: {
      title: 'Home Page',
      keepAlive: true,
      requireAuth: true,
    },
    component: () => import('@/pages/Index.vue'),
  },
  {
    path: '/meeting',
    name: ON_MEETING,
    meta: {
      title: 'On meeting',
      keepAlive: true,
      requireAuth: true,
    },
    component: () => import('@/pages/Meeting.vue'),
  },
  {
    path: '/conversation/:id',
    name: CONVERSATION,
    meta: {
      title: 'Conversation',
      requireAuth: true,
      keepAlive: false,
    },
    component: () => import('@/pages/Conversation.vue'),
  },
];

export const router = createRouter({
  history: createWebHistory(),
  routes,
});
