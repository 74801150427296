<template>
  <div
    class="d-flex justify-center align-center flex-grow-1 h-100 w-100 text-white"
  >
    <v-progress-circular
      indeterminate
      :size="25"
      :width="3"
      color="primary"
      class="mr-4"
    />
    Loading page...
  </div>
</template>
