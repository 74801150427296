<script setup lang="ts">
import { WEBAPP_URL } from '@/api/config';
import { useTimeAgo } from '@vueuse/core';
import zoomSdk from '@zoom/appssdk';

const date = '__DATE__';
const timeAgo = useTimeAgo(date);
const open = () => zoomSdk.openUrl({ url: WEBAPP_URL });
</script>

<template>
  <v-footer class="d-flex flex-column flex-grow-0">
    <v-btn variant="plain" :href="WEBAPP_URL" @click="open">Noty.ai</v-btn>
    <div class="d-none">Built at: {{ date }} ({{ timeAgo }})</div>
  </v-footer>
</template>
