import is from '@sindresorhus/is';

if (!is.string(import.meta.env.VITE_BACKEND_URL)) {
  throw new Error('Missing VITE_TRPC_BACKEND_URL');
}
if (!is.string(import.meta.env.VITE_WEBAPP_URL)) {
  throw new Error('Missing VITE_WEBAPP_URL');
}
if (!is.string(import.meta.env.VITE_SUPABASE_URL)) {
  throw new Error('Missing VITE_SUPABASE_URL');
}
if (!is.string(import.meta.env.VITE_SUPABASE_KEY)) {
  throw new Error('Missing VITE_SUPABASE_KEY');
}

export const BACKEND_URL = import.meta.env.VITE_BACKEND_URL;
export const SUPABASE_URL = import.meta.env.VITE_SUPABASE_URL;
export const SUPABASE_KEY = import.meta.env.VITE_SUPABASE_KEY;
export const WEBAPP_URL = import.meta.env.VITE_WEBAPP_URL;
