<script setup lang="ts">
import { usePrimaryWorkspace } from '@/components/Header/hooks/use-user';
import { VSkeletonLoader } from 'vuetify/labs/VSkeletonLoader';
import { computed } from 'vue';

enum Pricing {
  PERSONAL = 'PERSONAL',
  PERSONAL_PRO = 'PERSONAL_PRO',
  TEAM = 'TEAM',
}

const { data: workspace, isLoading } = usePrimaryWorkspace();
const plan = computed<string>(
  () =>
    ({
      [Pricing.PERSONAL]: 'Free',
      [Pricing.PERSONAL_PRO]: 'Personal',
      [Pricing.TEAM]: 'Business',
    })[workspace.value?.pricing ?? Pricing.PERSONAL],
);
</script>

<template>
  <v-skeleton-loader :loading="isLoading" type="sentences">
    <div class="flex flex-column pr-2 text-right">
      <div class="text-body-2" v-text="workspace.name" />
      <span class="text-caption" v-text="plan" />
    </div>
  </v-skeleton-loader>
</template>
